// eslint-disable-next-line no-use-before-define
import { Typography } from '@mui/material';
import React from 'react';
import { Item, ItemDetails } from '../Investments/investor-application';

export default function ExitLoad({
  exitLoad,
  isreadOnly = false,
}: {
  exitLoad?: string;
  isreadOnly?: boolean;
}): JSX.Element {
  return (
    <>
      {exitLoad && !isreadOnly && (
        <>
          <Item sx={{ fontSize: 16, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 }}>
            Exit Load
          </Item>
          <Typography sx={{ color: 'text.secondary' }}>{exitLoad}</Typography>
        </>
      )}
      {exitLoad && isreadOnly && (
        <>
          <Item>Exit Load</Item>
          <ItemDetails> {exitLoad}</ItemDetails>
        </>
      )}
    </>
  );
}
