import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { DistributorListType, RmsType } from '../../redux-store/types/api-types';
import { getDistributorById, getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';

export default function UserManagementDistributorDetails({
  location,
}: {
  location: Location<{ distributorId: number }>;
}): JSX.Element {
  const { distributorId: id } = location.state || { distributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState<DistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(id)
          )) as unknown as DistributorListType;
          setDistributorDetails(getDistributorDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('distributors', {
                  distributorType: distributorDetails?.type,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('distributors', {
                    distributorType: distributorDetails?.type,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
                textTransform: 'capitalize',
              }}>
              {distributorDetails?.type
                ? distributorDetails?.type + ' Distributor Detail'
                : 'Distributor Detail'}
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-distributor', { distributorId: distributorDetails?.id })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Distributor Name'} description={distributorDetails.name} />
                    {distributorDetails.type === 'individual' && (
                      <>
                        <KeyValue title={'Email Id'} description={distributorDetails.user.email} />
                        <KeyValue
                          title={'Contact Number'}
                          description={distributorDetails.user.phone}
                        />
                      </>
                    )}
                    <KeyValue title={'Pan'} description={distributorDetails.panNumber} />
                    <KeyValue title={'ARN Code'} description={distributorDetails.arnCode} />
                    {distributorDetails.type === 'individual' && (
                      <KeyValue title={'RM Name'} description={distributorDetails.rm.name} />
                    )}
                    <KeyValue title={'Pincode'} description={distributorDetails.pincode} />
                    <KeyValue
                      title={'Building Number'}
                      description={distributorDetails.buildingNo}
                    />
                    <KeyValue title={'Street Name'} description={distributorDetails.streetName} />
                    <KeyValue title={'State'} description={distributorDetails.state} />
                    <KeyValue title={'City'} description={distributorDetails.city} />
                    <KeyValue title={'Country'} description={distributorDetails.country} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
