import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  addressTypesMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  genderMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { FATCAValidationSchema } from '../../utils/schema';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';

export type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: string[];
};

const initialValues: Values = {
  applicants: [
    {
      gender: '',
      typeOfAddressProvidedAtKRA: '',
      taxResidentOfAnyCountryOtherThanIndia: false,
      placeOfBirth: '',
      countryOfBirth: 'INDIA',
      countryOfNationality: 'INDIA',
      taxCountryName: '',
      taxID: '',
      idType: '',
      nameOfEntity: '',
      dateOfIncorporation: '',
      cityOfIncorporation: '',
      countryOfIncorporation: '',
      entityExcemptionCode: '',
    },
  ],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <SubHeading>FATCA Details of {applicantName} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
          label="Type of Address Provided At KRA *"
          items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
        />
      </Grid>
      <Grid item xs={12}>
        <UseRadioGroup
          formLabel="Tax Resident of any country other than India *"
          name={`applicants.${index}.taxResidentOfAnyCountryOtherThanIndia`}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
          disabled={isFieldDisabled}
        />
      </Grid>
      {/* {applicant?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && ( */}
      <>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.placeOfBirth`}
            label="Place of Birth *"
            placeholder="Enter Place of Birth"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            placeholder="Enter Country of Birth"
            disabled={isFieldDisabled}
          /> */}
          <MFSelectField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={isFieldDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            placeholder="Enter Country of Nationality"
            disabled={isFieldDisabled}
          /> */}
          <MFSelectField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={isFieldDisabled}
          />
        </Grid>
        {values?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && (
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxCountryName`}
                label="Tax country Name *"
                placeholder="Enter Tax country Name"
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxID`}
                label="Tax ID *"
                placeholder="Enter Tax ID"
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.idType`}
                label="ID Type *"
                placeholder="Enter ID Type"
                disabled={isFieldDisabled}
              />
            </Grid>
          </>
        )}
        {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.nameOfEntity`}
              label="Name of Entity"
              placeholder="Enter Name of Entity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date of Incorporation"
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={minDateForContributor()}
              name={`applicants.${index}.dateOfIncorporation`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={applicant.dateOfBirth || minDateForContributor().toString()}
              onChange={(newValue) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant, ind) => {
                    if (index === ind) {
                      return {
                        ...applicant,
                        dateOfIncorporation: newValue?.toString(),
                      };
                    }
                    return applicant;
                  }),
                });
              }}
              textFieldStyles={{
                'label + &': {
                  mt: 4,
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #DDEAF3',
                },
                '& .MuiOutlinedInput-root': {
                  position: 'relative',
                  backgroundColor: 'common.white',
                  border: '1px solid #DDEAF3',
                  fontSize: 16,
                  '&:hover': {
                    borderColor: 'primary.main',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '.MuiInputBase-input': {
                    p: '10px 12px',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.cityOfIncorporation`}
              label="City of Incorporation"
              placeholder="Enter City of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.countryOfIncorporation`}
              label="Country of Incorporation"
              placeholder="Enter Country of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.entityExcemptionCode`}
              label="Entity Exemption Code"
              placeholder="Enter Entity Exemption Code"
            />
          </Grid> */}
      </>
      {/* )} */}
    </>
  );
};

export default function Fatca(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setFatcaDetails({
          ...fatcaDetails,
          applicants: applicants?.map((applicant) => ({
            gender: applicant.gender || '',
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxResidentOfAnyCountryOtherThanIndia:
              applicant.taxResidentOfAnyCountryOtherThanIndia || false,
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth: applicant.countryOfBirth
              ? applicant.countryOfBirth.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfNationality: applicant.countryOfNationality
              ? applicant.countryOfNationality.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || '',
            idType: applicant.idType || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
          })),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        const {
          taxResidentOfAnyCountryOtherThanIndia = false,
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];
        return {
          ...applicant,
          taxResidentOfAnyCountryOtherThanIndia:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false' ? false : true,
          // dateOfBirth: dateOfBirth ? dateOfBirth : null,
          dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
          ...rest,
          placeOfBirth: removeSingleQuote(rest.placeOfBirth),
          taxCountryName: removeSingleQuote(rest.taxCountryName),
          taxID: removeSingleQuote(rest.taxID),
          idType: removeSingleQuote(rest.idType),
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedPayload,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 5,
              //!!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={'FATCA Information is mandatory to disclose any Foreign Citizenship'}
          />
          {values.applicants.map((applicant, index) => (
            <FatcaDetails
              applicantName={getApplicantName(index + 1)}
              index={index}
              key={index}
              values={applicant}
              nationalitiesMdmsResponse={mdmsCountriesList}
            />
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
