import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { formatShortDate } from '../../utils/date';
import {
  currencyConversion,
  isFieldApplicable,
  isFieldFixed,
  isSetupFeeApplicable,
  schemaCodeNTorNS,
  schemaCodeNU,
  setupFeeAsPerPPM,
} from '../../utils/utilityFunctions';
import { SETUP_FEE_APPLICABLE_MASTER } from '../../utils/constant';
import ExitLoad from './exitLoad';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                <KeyValue
                  title={'Commitment Amount'}
                  description={currencyConversion(application.commitmentAmount)}
                />
                <KeyValue
                  title={
                    schemaCodeNTorNS(application.scheme?.schemeCode || '')
                      ? 'Investment Scheme Management Fee'
                      : 'Management Fee'
                  }
                  description={
                    application.managementFeesRate ||
                    application.managementFeesRate?.toString() === '0'
                      ? schemaCodeNU(application.scheme?.schemeCode || '')
                        ? `${application.managementFeesRate}% (plus applicable GST) P.A.`
                        : schemaCodeNTorNS(application.scheme?.schemeCode || '')
                        ? 'Up to ' + application.managementFeesRate + '% (One time deal wise)'
                        : application.managementFeesRate
                      : application.managementFeesRate
                  }
                />
                {schemaCodeNTorNS(application.scheme?.schemeCode || '') && (
                  <KeyValue
                    title={'Fixed Management Fee'}
                    description={
                      application.fixedManagementFee
                        ? application.fixedManagementFee + ' (plus applicable GST) per annum'
                        : application.fixedManagementFee
                    }
                  />
                )}
                {isFieldApplicable(application?.performanceFeeApplicable || '') && (
                  <KeyValue
                    title={'Performance Fee'}
                    description={
                      schemaCodeNU(application.scheme?.schemeCode || '')
                        ? application?.performanceFee === 0 || application?.performanceFee
                          ? `${application?.performanceFee}% (plus applicable GST) P.A.`
                          : application?.performanceFee
                        : application?.performanceFee
                    }
                  />
                )}

                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                <KeyValue
                  title={'Contribution Amount'}
                  description={currencyConversion(application.contributionChequeAmount)}
                />
                {isSetupFeeApplicable(application.setupFeeApplicable || '') ? (
                  <>
                    <KeyValue
                      title={'Setup Fee Percentage'}
                      description={application.setupFeePercentage}
                    />
                    <KeyValue
                      title={'Setup Fee Amount'}
                      description={currencyConversion(application.setupFeeAmount)}
                    />
                    <KeyValue
                      title={'GST (GST On Setup Fee Amount)'}
                      description={currencyConversion(application.setupFeeGST)}
                    />
                    <KeyValue
                      title={'Total Setup Fee'}
                      description={currencyConversion(application.totalSetupFee)}
                    />
                    <KeyValue
                      title={'TDS'}
                      description={currencyConversion(application.setupFeeTDS)}
                    />
                    <KeyValue
                      title={'Setup Fee Payable'}
                      description={currencyConversion(application.netSetupFeeLessTDS)}
                    />
                  </>
                ) : (
                  setupFeeAsPerPPM(application.setupFeeApplicable || '') && (
                    <KeyValue title="Setup Fee" description={SETUP_FEE_APPLICABLE_MASTER.ppm} />
                  )
                )}

                <KeyValue
                  title={'Total Amount Payable'}
                  description={currencyConversion(application.totalAmountPayable)}
                />
                {isFieldApplicable(application?.isOperatingExpensesApplicable || '') &&
                  (isFieldFixed(
                    application?.operatingExpensesType || '',
                    application?.isOperatingExpensesApplicable || ''
                  ) ? (
                    <KeyValue
                      title="Operating Expenses"
                      description={application.operatingExpenses}
                    />
                  ) : (
                    <KeyValue
                      title="Operating Expenses In Words"
                      description={application.operatingExpensesInWord}
                    />
                  ))}
                <Grid item xs={12} pb={2}>
                  <ExitLoad exitLoad={application?.plan?.exitLoad} isreadOnly={true} />
                </Grid>
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
