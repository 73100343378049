/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  LoginWithOtpRequestBody,
  LoginWithOtpResponseBody,
  VerifyLoginOtpRequestBody,
  VerifyLoginOtpResponseBody,
} from '../types/api-types';
import {
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_LOGIN_OTP,
  VERIFY_LOGIN_OTP_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO,
  SHOW_GLOBAL_ERROR,
} from '../types/auth';

export const loginWithOtp =
  (body: LoginWithOtpRequestBody) =>
  async (dispatch: any): Promise<LoginWithOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/login/send-otp`,
        method: 'POST',
        types: [LOGIN_WITH_OTP_SUCCESS, LOGIN_WITH_OTP],
        body,
      },
    });
  };

export const verifyLoginOtp =
  (body: VerifyLoginOtpRequestBody) =>
  async (dispatch: any): Promise<VerifyLoginOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/login/verify-otp`,
        method: 'POST',
        types: [VERIFY_LOGIN_OTP_SUCCESS, VERIFY_LOGIN_OTP],
        body,
      },
    });
  };

export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const getUserInfo = () => {
  return {
    [CALL_API]: {
      url: `/users/users/me`,
      method: 'GET',
      types: [GET_USER_INFO_SUCCESS, GET_USER_INFO],
    },
  };
};

export const showError = (message: string) => {
  return {
    type: SHOW_GLOBAL_ERROR,
    errorType: 'ALERT',
    message,
  };
};
