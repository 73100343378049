import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Item, ItemDetails } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { SubHeading } from '../investors/components';

export const getFieldTitle: { [key: string]: string } = {
  addressProof: 'Address Proof',
  identityProof: 'Identity Proof',
  bankAccountProof: 'Bank Account Proof',
  poaNotarized: 'Poa Notarized Document',
  investorsConsent: 'Investors Consent Document',
  fatca: 'Fatca Document',
  form_10ff_trc: 'Form 10fF/TRC',
  additional: 'Additional Document',
  investorPhotograph: 'Investor Photograph',
  investorSignature: 'Investor Signature',
  compliance_document: 'Compliance Document',
};

export default function DocumentDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Document Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  {applicant.documents && applicant.documents.length !== 0 && (
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  )}
                  {applicant.documents && applicant.documents.length === 0 && index === 0 && (
                    <Grid
                      xs={12}
                      sm={12}
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'common.black',
                        textAlign: 'center',
                        my: 3,
                      }}>
                      No Documents
                    </Grid>
                  )}
                  <Grid
                    container
                    pt={applicant.documents && applicant.documents.length && 2}
                    pb={applicant.documents && applicant.documents.length && 5}
                    columnSpacing={13}
                    sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                    {applicant.documents &&
                      applicant.documents.map((document, index: number) => {
                        return (
                          <React.Fragment key={document.id}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={document.file ? 6 : 12}
                              sx={{ mt: index !== 0 ? 6 : 0 }}>
                              <Item>{getFieldTitle[document.documentType]}</Item>
                              {document.documentType === 'investorSignature' && (
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}>
                                  (This signature copy is used as a verification process for future
                                  redemptions and transfer requests)
                                </Typography>
                              )}
                              <ItemDetails>
                                {document.documentName?.toLowerCase() ===
                                getFieldTitle[document.documentType]?.toLowerCase()
                                  ? document.file?.name
                                  : document.documentName}
                              </ItemDetails>
                            </Grid>
                            {document.file && (
                              <Grid item xs={12} sm={6}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: 'common.white',
                                    minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                    mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                    fontWeight: 600,
                                    lineHeight: 1.5,
                                  }}
                                  onClick={() => window.open(document.file?.url)}>
                                  View
                                </Button>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
