import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsResponse,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL, USER_ROLES } from '../../utils/constant';
import { InfoDialog, Notes } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { applicationComparison, applyRoleBasedStatus } from '../../utils/utilityFunctions';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  filledBy: string | null;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = () => {
  return (
    <>
      <GridItem>
        <MFTextField name="firstName" label="RM Name" placeholder="Enter RM name" disabled={true} />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RM Email ID"
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GridItem>
        <MFSelectField
          name="distributorId"
          label="Distributor Code *"
          items={distributors.map((item) => ({
            key: item.arnCode,
            value: item.id,
          }))}
          onChange={({ target: { value } }) => {
            const distributorCode = distributors.find((item) => item.id === value);
            const { id = null, name } = distributorCode || {};
            setValues({
              ...values,
              distributorName: name,
              distributorId: id,
              subdistributorId: null,
              subdistributorName: null,
              subdistributorEmail: null,
            });
            setSelectedDistributor(
              distributors.find((distributor) => distributor.id === value)?.subdistributors || []
            );
          }}
          disabled={[
            USER_ROLES.DISTRIBUTOR,
            USER_ROLES.INVESTOR,
            USER_ROLES.POAAPPROVER,
            USER_ROLES.SUBDISTRIBUTOR,
          ].includes(role)}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="distributorName"
          label="Distributor Name *"
          placeholder="Enter Distributor name"
          disabled={true}
        />
      </GridItem>
      {!!selectedDistributor?.length && (
        <>
          <GridItem>
            <MFSelectField
              name="subdistributorId"
              label="Distributor's RM Code - Name *"
              items={selectedDistributor.map((item) => ({
                key: (item.distributors_RM_Code || 'NA') + ' - ' + item.user.firstName,
                value: item.id,
              }))}
              onChange={({ target: { value } }) => {
                const distributorCode = selectedDistributor.find((item) => item.id === value);
                const { id = null, user } = distributorCode || {};
                const { email } = user || {};
                setValues({
                  ...values,
                  subdistributorId: id,
                  subdistributorEmail: email,
                });
              }}
              disabled={[
                USER_ROLES.DISTRIBUTOR,
                USER_ROLES.INVESTOR,
                USER_ROLES.POAAPPROVER,
                USER_ROLES.SUBDISTRIBUTOR,
              ].includes(role)}
            />
          </GridItem>
          {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
          <GridItem>
            <MFTextField
              name="subdistributorEmail"
              label="Distributor's RM Email *"
              placeholder="Enter Distributor email"
              disabled={true}
            />
          </GridItem>
        </>
      )}
      <GridItem>
        <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" />
        <Typography
          component="span"
          sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
          <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
        </Typography>
      </GridItem>
      <InfoDialog
        onClose={handleClose}
        open={open}
        content={'Please add the Name of the Subdistributor RM.'}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const {
    distributor,
    rm,
    subdistributorId = null,
    subDistributor,
    filledBy = '',
  } = application || {};

  const { name, id } = distributor || {
    arnCode: '',
    name: '',
    id: null,
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '' },
  } = rm || { empCode: '', user: { email: '' } };

  const {
    user: { firstName: subdistributorName, email: subdistributorEmail },
  } = subDistributor || { user: { firstName: '', email: '' } };

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: 'rm',
    empCode,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    filledBy,
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          const res = (await dispatch(
            getDistributors({ isActive: true })
          )) as unknown as getDistributorsResponse;
          const { distributors = [] } = res || {};
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor],
                  } as Distributor,
                ]
              : distributors;
          if (isComponentActive) {
            const updateSubdistributor =
              application?.distributor_id &&
              application?.distributor.isActive &&
              application?.subdistributorId &&
              !application.subDistributor?.isActive
                ? [
                    ...(updatedDistributor.find((distributor) => distributor.id === id)
                      ?.subdistributors || []),
                    { ...application.subDistributor },
                  ]
                : updatedDistributor.find((distributor) => distributor.id === id)
                    ?.subdistributors || [];
            setSelectedDistributor(updateSubdistributor as unknown as SubDistributor[]);
            setDistributors(updatedDistributor as unknown as Distributor[]);
          }
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application]);

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const { distributorId, subdistributorId, filledBy } = values;
      const { id, applicant1ReferenceId, currentStep = null, status, hasPOA } = application || {};
      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        currentStep: currentStep === null ? 1 : currentStep,
      });
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              distributor_id: distributorId,
              subdistributorId,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              filledBy,
            },
            applicationId: id,
          })
        );
        history.push('contributor-details', { id, applicant1ReferenceId });
      } else if (checkApplication) {
        history.push('contributor-details', { id, applicant1ReferenceId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(selectedDistributor)}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies whether the application is from an RM/Distributor’s RM.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5}>
            {([USER_ROLES.RM, USER_ROLES.INVESTOR].includes(role) ||
              (USER_ROLES.AMCAPPROVER === role && AMC_APPROVER_CHECK_FOR_INDIVIDUAL)) &&
              !!distributors.length && (
                <Grid item xs={12}>
                  <UseRadioGroup
                    formLabel=""
                    name={`loginType`}
                    items={[
                      { label: 'RM', value: USER_ROLES.RM },
                      { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                    ]}
                  />
                </Grid>
              )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
              />
            )}
          </Grid>
          <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5 }}>
            <MFSubmitButton label="Next" />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
