import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Applicant } from '../../redux-store/types/api-types';
import { Gridstyles } from '../ApplicationDetails';
import { KeyValue } from '../ApplicationDetails/contributor';
import { SubHeading } from '../investors/components';

export default function ContactDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Contact Details
            </Typography>
            {applicants.map((applicant) => {
              const {
                contactperson = {
                  name: '',
                  mobile: '',
                  email: '',
                  landLineNumber: '',
                  pincode: '',
                  address1: '',
                  address2: '',
                  state: '',
                  city: '',
                  country: '',
                  stdCode: '',
                },
              } = applicant || {};
              return (
                <React.Fragment key={applicant.id}>
                  {/* <Grid> */}
                  {!!applicant?.addresses?.length &&
                    applicant?.addresses.map((address) => (
                      <React.Fragment key={address.id}>
                        <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                          {address.address_type === 'permanent'
                            ? 'Registered Office Address'
                            : 'Correspondance Address'}
                        </SubHeading>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pt={2}
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={13}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <KeyValue title={'Pincode'} description={address.pincode} />
                            <KeyValue title={'Address 1'} description={address.address1} />
                            <KeyValue title={'Address 2'} description={address.address2} />
                            <KeyValue title={'State'} description={address.state} />
                            <KeyValue title={'City'} description={address.city} />
                            <KeyValue title={'Country'} description={address.country} />
                          </Grid>
                        </Gridstyles>
                      </React.Fragment>
                    ))}
                  {/* </Grid> */}
                  <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                    {'Details of Contact Person'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={2}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name Of Contact Person'} description={contactperson.name} />
                      <KeyValue
                        title={'Mobile Number'}
                        description={
                          contactperson.countryCode
                            ? contactperson.countryCode + '-' + contactperson.mobile
                            : contactperson.mobile
                        }
                      />
                      <KeyValue title={'Email ID'} description={contactperson.email} />
                      <KeyValue
                        title={'Landline'}
                        description={
                          contactperson.stdCode && contactperson.landLineNumber
                            ? contactperson.stdCode + '-' + contactperson.landLineNumber
                            : null
                        }
                      />
                      <KeyValue title={'Pincode'} description={contactperson.pincode} />
                      <KeyValue title={'Building Number'} description={contactperson.address1} />
                      <KeyValue title={'Street Name'} description={contactperson.address2} />
                      <KeyValue title={'State'} description={contactperson.state} />
                      <KeyValue title={'City'} description={contactperson.city} />
                      <KeyValue title={'Country'} description={contactperson.country} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
