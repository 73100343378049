import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Applicant, DocumentType } from '../../redux-store/types/api-types';
import { Item, ItemDetails } from '../ApplicationDetails';
import { SubHeading } from '../investors/components';

export default function Documents({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  function getFieldTitle(value: string): string {
    const fieldTitle = [
      'Identity Proof',
      'Address Proof',
      'Bank Account Proof',
      'Poa Notarized Document',
      'Investors Consent Document',
      'Fatca Document',
      'Additional Document',
    ];
    const finalValue = fieldTitle
      .map((titleItem) => {
        if (
          titleItem.split(' ').join('').toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) != -1
        ) {
          return titleItem;
        }
      })
      .filter((item) => item);
    return finalValue.join('');
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Document Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const { documents = [] } = applicant;
              const updatedDocuments = documents.reduce((prev: DocumentType[], curr) => {
                const { documentType } = curr;
                const modifiedDocTypes = prev.map((doc) => doc.documentType);
                const docsOfCurrentDocType = documents.filter(
                  (doc) =>
                    doc.documentType === documentType && !modifiedDocTypes.includes(documentType)
                );
                const updatedDocs =
                  docsOfCurrentDocType.length > 1
                    ? docsOfCurrentDocType.map((doc, index) => ({
                        ...doc,
                        documentName: `${doc.documentName} (${index + 1})`,
                      }))
                    : docsOfCurrentDocType;
                return [...prev, ...updatedDocs];
              }, []) as DocumentType[];

              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  {updatedDocuments && updatedDocuments.length !== 0 && (
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  )}
                  {updatedDocuments && updatedDocuments.length === 0 && index === 0 && (
                    <Grid
                      xs={12}
                      sm={12}
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'common.black',
                        textAlign: 'center',
                        my: 3,
                      }}>
                      No Documents
                    </Grid>
                  )}
                  <Grid
                    container
                    pt={updatedDocuments && updatedDocuments.length && 2}
                    pb={updatedDocuments && updatedDocuments.length && 5}
                    columnSpacing={13}
                    sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                    {updatedDocuments &&
                      updatedDocuments.map((document, index: number) => {
                        return (
                          <React.Fragment key={document.id}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={document.file ? 6 : 12}
                              sx={{ mt: index !== 0 ? 6 : 0 }}>
                              <Item>
                                {getFieldTitle(document.documentType) ===
                                'Investors Consent Document'
                                  ? "Investor's Consent Document"
                                  : getFieldTitle(document.documentType)}
                              </Item>
                              <ItemDetails>
                                {document.documentName || document.file?.name}
                              </ItemDetails>
                            </Grid>
                            {document.file && (
                              <Grid item xs={12} sm={6}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: 'common.white',
                                    minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                    mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                    fontWeight: 600,
                                    lineHeight: 1.5,
                                  }}
                                  onClick={() => window.open(document.file?.url)}>
                                  View
                                </Button>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
